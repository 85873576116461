import * as React from 'react';

import Paper from '@mui/material/Paper';

const Contact = ({ contact }) => {

    return (
        <Paper
            style={{
                padding: 20,
                flex: 1,
                height: 500
            }}
            elevation={3} 
        >
            <p>{contact.name}</p>
            <p>{contact.email}</p>
            <p>{contact.subject}</p>
            <p>{contact.message}</p>
        </Paper>
    )
}

export default Contact;