import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import Contact from './contact';
import DataService from '../../services/dataService';


const ContactListItem = ({ contact, updateContact, updateContacts, setSelectedContact }) => {

    const [clicked, setClicked] = React.useState(contact.viewed);

    return (
        <ListItem 
            alignItems="flex-start"
            style={{
                shadowColor: '#470000',
                shadowOffset: {width: 0, height: 1},
                shadowOpacity: 0.2,
                elevation: 1,
                position: 'relative',
                paddingTop: 0,
                margin: 5,
                backgroundColor: '#fff'
            }}
            onClick={(e) => {
                setSelectedContact(contact);
                updateContact(contact);
                updateContacts();
                setClicked(true);
            }}
        >

            <ListItemText
                primary={
                    <React.Fragment>
                        <p>{contact.subject} - <small style={{ color: 'grey' }}>{contact.email}</small></p>
                    </React.Fragment>
                }
                secondary={
                    <React.Fragment>
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        {contact.name}
                    </Typography>
                        - {contact.message}
                        <p style={{ fontFamily: 'monospace' }}>{contact.recieved}</p>
                    </React.Fragment>
                }
            />
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: '100%',
                    width: 10,
                    backgroundColor: clicked ? 'lightblue' : 'blue'
                }}
            >
                
            </div>
        </ListItem>
    )
}

const ContactsList = ({ contacts, setSelectedContact, selectedContact, updateContacts }) => {

    const updateContact = (contact) => {

        DataService.updateRecord(selectedContact.id.split('-')[1], {
            viewed: true
        }).then(res => {
            console.log(res);
        })
    }

    return (
        <List sx={{ 
            width: '100%', 
            maxWidth: 360, 
            bgcolor: 'background.paper', 
            paddingTop: 0, 
            paddingBottom: 0,
            maxHeight: 'inherit',
            backgroundColor: 'whitesmoke'
        }}>
            {
                contacts.map( ( contact, index ) => (
                    <ContactListItem 
                        key={index} 
                        contact={contact}
                        updateContact={updateContact}
                        updateContacts={updateContacts} 
                        setSelectedContact={setSelectedContact}
                    />
                ))
            }
        </List>
    )
}

export default ContactsList;