import * as React from 'react';

import Contact from '../../components/contacts/contact';
import ContactList from '../../components/contacts/contactsList';
import Layout from './Layout';

import { graphql } from 'gatsby';


const Contacts = ({ data }) => {

    const [contacts, setContacts] = React.useState(
        data.allDatoCmsContact.edges.map( contact => contact.node )
    );
    const [selectedContact, setSelectedContact] = React.useState(contacts[0] ? contacts[0] : null);
    
    const updateContacts = (contact, index) => {

        let cContacts = contacts;
        cContacts.veiewd = true;
        setContacts(cContacts);
    }
    
    React.useEffect(() => {
        console.log(contacts)
    }, []);

    return (
        <Layout>
            <h2 style={{ fontFamily: 'monospace' }}>
                Contacts
            </h2>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>
                    <ContactList 
                        setSelectedContact={setSelectedContact} 
                        selectedContact={selectedContact}
                        contacts={contacts}
                        updateContacts={updateContacts}
                    />
                </div>
                <Contact style={{ flex: 1 }} contact={selectedContact} />
            </div>
        </Layout>
    )
}

export const query = graphql`
    query contactsQuery($sitename: String) {
        allDatoCmsContact(filter: {site: {name: {eq: $sitename}}}) {
            edges {
                node {
                    id
                    subject
                    name
                    email
                    message
                    recieved
                    viewed
                }
            }
        }
    }
`

export default Contacts;